import React from "react"
import ReactPlayer from 'react-player'
import ReactWebMediaPlayer from "react-web-media-player"

import styled from "styled-components"

import myVideo from '../images/video/Cloud_Castles_v.2_15.01.2021.mp4'

const ExplanationVideo = (props) => {



  return (
    <Container>



          <Content>
            {/* {start_button ? ( <CTAButton type="submit">Start Owning </CTAButton>  ) : ()           */}
            <ReactPlayer           width={'100%'}
          height={'100%'} controls={true} muted={true}   playing={true} loop={true} url={myVideo} /> 
          
            
          </Content>

    </Container>
  )
}

export default ExplanationVideo

const Container = styled.div`
  position: relative;

  
  justify-content:center;
  align-content:center;
  @media (max-width: 600px) {
      max-height: 600px;
  }
  border-radius: 25px;
  border: 5px solid white;
`


/*display: grid;*/
/*    grid-template-columns: repeat(2, auto);*/
/*min-height:400px;*/
const Content = styled.div`
  
  
 
  width: 100%;
  height: 100%;
  display: flex;
  
  justify-content: center;
  align-items: center;
  @media (max-width: 1576px) {
    

  }
`
const SubContent = styled.div`
  max-width: 100%;
  margin: 50px 100px 50px 100px;
  display: grid;
  grid-template-columns: repeat(2, auto);

  /*align vertical / justify is horizontal*/
  align-items: center;
  justify-items: center;
`

const MarContent = styled.div`
  margin: 0px 100px 0px 0px;
   @media (max-width: 1024px) {
    width:60%
    height:60%
  }
`
const H1Text = styled.h1`
  max-width: 450px;
  align-items: center;
  text-align: center;
  font-size: 80px;
  font-weight: bold;
  padding: 0px auto;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 10s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  opacity: 0;
  bottom: 0;
    @media (max-width: 1024px) {
    font-size: 50px;
    padding:100px auto;
  }
`

const H1TextButton = styled.button`
  /*animation: ContentAnimations 3s forwards;*/
  font-weight: bold;
  margin-top: 30px;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  padding: 10px 5px;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1.25rem;
  border-radius: 5px;
  &:hover {
    background-color: #f1f1f1;
    color: #000000c0;
    animation: SubContentAnimations;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0, 0, 0, 0.25);
  }
`

const CTAButton = styled.button`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1461px;
  line-height: 24px;

  color: #ffffff;
  

  background: #8578fc;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`